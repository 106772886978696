@import "00-configuration/_configuration.scss";

.ce-align-left {
    text-align: left;
}

.ce-align-center {
    text-align: center;
}

.ce-align-right {
    text-align: right;
}

.ce-table td, .ce-table th {
    vertical-align: top;
}

.ce-textpic, .ce-image, .ce-nowrap .ce-bodytext, .ce-gallery, .ce-row, .ce-uploads li, .ce-uploads div {
    overflow: hidden;
}

.ce-left .ce-gallery, .ce-column {
    float: left;
}

.ce-center .ce-outer {
    position: relative;
    float: right;
    right: 50%;
}

.ce-center .ce-inner {
    position: relative;
    float: right;
    right: -50%;
}

.ce-right .ce-gallery {
    float: right;
}

.ce-gallery figure {
    display: table;
    margin: 0;
}

.ce-gallery figcaption {
    display: table-caption;
    caption-side: bottom;
}

.ce-gallery img {
    display: block;
}

.ce-gallery iframe {
    border-width: 0;
}

.ce-border img, .ce-border iframe {
    border: 2px solid #000000;
    padding: 0px;
}

.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
    margin-bottom: 10px;
}

.ce-intext.ce-right .ce-gallery {
    margin-left: 10px;
}

.ce-intext.ce-left .ce-gallery {
    margin-right: 10px;
}

.ce-below .ce-gallery {
    margin-top: 10px;
}

.ce-column {
    margin-right: 10px;
}

.ce-column:last-child {
    margin-right: 0;
}

.ce-row {
    margin-bottom: 10px;
}

.ce-row:last-child {
    margin-bottom: 0;
}

.ce-above .ce-bodytext {
    clear: both;
}

.ce-intext.ce-left ol, .ce-intext.ce-left ul {
    padding-left: 40px;
    overflow: auto;
}

.ce-headline-left {
    text-align: left;
}

.ce-headline-center {
    text-align: center;
}

.ce-headline-right {
    text-align: right;
}

.ce-uploads {
    margin: 0;
    padding: 0;
}

.ce-uploads li {
    list-style: none outside none;
    margin: 1em 0;
}

.ce-uploads img {
    float: left;
    padding-right: 1em;
    vertical-align: top;
}

.ce-uploads span {
    display: block;
}

.ce-table {
    width: 100%;
    max-width: 100%;
}

.ce-table th, .ce-table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
}

.ce-table thead th {
    border-bottom: 2px solid #dadada;
}

.ce-table th, .ce-table td {
    border-top: 1px solid #dadada;
}

.ce-table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.ce-table-bordered th, .ce-table-bordered td {
    border: 1px solid #dadada;
}

.frame-space-before-extra-small {
    margin-top: 1em;
}

.frame-space-before-small {
    margin-top: 2em;
}

.frame-space-before-medium {
    margin-top: 3em;
}

.frame-space-before-large {
    margin-top: 4em;
}

.frame-space-before-extra-large {
    margin-top: 5em;
}

.frame-space-after-extra-small {
    margin-bottom: 1em;
}

.frame-space-after-small {
    margin-bottom: 2em;
}

.frame-space-after-medium {
    margin-bottom: 3em;
}

.frame-space-after-large {
    margin-bottom: 4em;
}

.frame-space-after-extra-large {
    margin-bottom: 5em;
}

.frame-ruler-before:before {
    content: '';
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    margin-bottom: 2em;
}

.frame-ruler-after:after {
    content: '';
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    margin-top: 2em;
}

.frame-indent {
    margin-left: 15%;
    margin-right: 15%;
}

.frame-indent-left {
    margin-left: 33%;
}

.frame-indent-right {
    margin-right: 33%;
}

.ol-mouse-position {
    top: 8px;
    right: 8px;
    position: absolute
}

.ol-scale-line {
    background: #95b9e6;
    background: rgba(0, 60, 136, .3);
    border-radius: 4px;
    bottom: 8px;
    left: 8px;
    padding: 2px;
    position: absolute
}

.ol-scale-line-inner {
    border: 1px solid #eee;
    border-top: none;
    color: #eee;
    font-size: 10px;
    text-align: center;
    margin: 1px;
    will-change: contents, width
}

.ol-overlay-container {
    will-change: left, right, top, bottom
}

.ol-unsupported {
    display: none
}

.ol-viewport .ol-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent
}

.ol-control {
    position: absolute;
    background-color: #eee;
    background-color: rgba(255, 255, 255, .4);
    border-radius: 4px;
    padding: 2px
}

.ol-control:hover {
    background-color: rgba(255, 255, 255, .6)
}

.ol-zoom {
    top: .5em;
    left: .5em
}

.ol-rotate {
    top: .5em;
    right: .5em;
    transition: opacity .25s linear, visibility 0s linear
}

.ol-rotate.ol-hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s linear, visibility 0s linear .25s
}

.ol-zoom-extent {
    top: 4.643em;
    left: .5em
}

.ol-full-screen {
    right: .5em;
    top: .5em
}

@media print {
    .ol-control {
        display: none
    }
}

.ol-control button {
    display: block;
    margin: 1px;
    padding: 0;
    color: #fff;
    font-size: 1.14em;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    height: 1.375em;
    width: 1.375em;
    line-height: .4em;
    background-color: #7b98bc;
    background-color: rgba(0, 60, 136, .5);
    border: none;
    border-radius: 2px
}

.ol-control button::-moz-focus-inner {
    border: none;
    padding: 0
}

.ol-zoom-extent button {
    line-height: 1.4em
}

.ol-compass {
    display: block;
    font-weight: 400;
    font-size: 1.2em;
    will-change: transform
}

.ol-touch .ol-control button {
    font-size: 1.5em
}

.ol-touch .ol-zoom-extent {
    top: 5.5em
}

.ol-control button:focus, .ol-control button:hover {
    text-decoration: none;
    background-color: #4c6079;
    background-color: rgba(0, 60, 136, .7)
}

.ol-zoom .ol-zoom-in {
    border-radius: 2px 2px 0 0
}

.ol-zoom .ol-zoom-out {
    border-radius: 0 0 2px 2px
}

.ol-attribution {
    text-align: right;
    bottom: .5em;
    right: .5em;
    max-width: calc(100% - 1.3em)
}

.ol-attribution ul {
    margin: 0;
    padding: 0 .5em;
    font-size: .7rem;
    line-height: 1.375em;
    color: #000;
    text-shadow: 0 0 2px #fff
}

.ol-attribution li {
    display: inline;
    list-style: none;
    line-height: inherit
}

.ol-attribution li:not(:last-child):after {
    content: " "
}

.ol-attribution img {
    max-height: 2em;
    max-width: inherit
}

.ol-attribution button, .ol-attribution ul {
    display: inline-block
}

.ol-attribution.ol-collapsed ul {
    display: none
}

.ol-attribution.ol-logo-only ul {
    display: block
}

.ol-attribution:not(.ol-collapsed) {
    background: rgba(255, 255, 255, .8)
}

.ol-attribution.ol-uncollapsible {
    bottom: 0;
    right: 0;
    border-radius: 4px 0 0;
    height: 1.1em;
    line-height: 1em
}

.ol-attribution.ol-logo-only {
    background: 0 0;
    bottom: .4em;
    height: 1.1em;
    line-height: 1em
}

.ol-attribution.ol-uncollapsible img {
    margin-top: -.2em;
    max-height: 1.6em
}

.ol-attribution.ol-logo-only button, .ol-attribution.ol-uncollapsible button {
    display: none
}

.ol-zoomslider {
    position: absolute;
    top: 4.5em;
    left: .5em;
    background: #eee;
    background: rgba(255, 255, 255, .4);
    width: 24px;
    height: 200px
}

.ol-zoomslider-thumb {
    position: absolute;
    background: #7b98bc;
    background: rgba(0, 60, 136, .5);
    border-radius: 2px;
    cursor: pointer;
    height: 10px;
    width: 22px;
    margin: 3px
}

.ol-touch .ol-zoomslider {
    top: 5.5em;
    width: 2.052em
}

.ol-touch .ol-zoomslider-thumb {
    width: 1.8em
}

.ol-overviewmap {
    position: absolute;
    left: .5em;
    bottom: .5em
}

.ol-overviewmap.ol-uncollapsible {
    bottom: 0;
    left: 0;
    border-radius: 0 4px 0 0
}

.ol-overviewmap .ol-overviewmap-map, .ol-overviewmap button {
    display: inline-block
}

.ol-overviewmap .ol-overviewmap-map {
    border: 1px solid #7b98bc;
    height: 150px;
    margin: 2px;
    width: 150px
}

.ol-overviewmap:not(.ol-collapsed) button {
    bottom: 1px;
    left: 2px;
    position: absolute
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map, .ol-overviewmap.ol-uncollapsible button {
    display: none
}

.ol-overviewmap:not(.ol-collapsed) {
    background: rgba(255, 255, 255, .8)
}

.ol-overviewmap-box {
    border: 2px dotted rgba(0, 60, 136, .7)
}

.popup-label {
    background-color: #fff;
    border: 2px #444 solid;
    border-radius: 7px;
    -webkit-box-shadow: 4px 4px 5px 0 rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 4px 4px 5px 0 rgba(50, 50, 50, 0.75);
    box-shadow: 4px 4px 5px 0 rgba(50, 50, 50, 0.75);
    color: #111;
    font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: bold;
    padding: 3px 6px;
    position: absolute;
    white-space: nowrap;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
}

.popup-label img {
    vertical-align: middle;
}

.popup-label.marker:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 12px 6px;
    border-color: transparent transparent #000 transparent;
}

.angular-openlayers-map:-moz-full-screen {
    height: 100%;
}

.angular-openlayers-map:-webkit-full-screen {
    height: 100%;
}

.angular-openlayers-map:full-screen {
    height: 100%;
}

.angular-openlayers-map:not(-moz-full-screen) {
    height: 400px;
}

.angular-openlayers-map:not(-webkit-full-screen) {
    height: 400px;
}

.angular-openlayers-map:not(full-screen) {
    height: 400px;
}

.ol-full-screen {
    position: absolute;
    top: 50%;
}

/* map styles */

body, html {
    height: 100%;
}

.carto {
    height: 100%;

    .page {
        height: 100%;
        width: 100%;
        display: table;
        table-layout: fixed;

        > .colPos0 {
            height: 100%;
            display: table-cell;
            vertical-align: top;
            width: 100%;
        }

        > .colPos90 {
            height: 100%;
            width: 340px;
            display: table-cell;
            vertical-align: top;
            margin: 0;
            padding: 0;
            background: $color-2--3;
        }
    }

    .filters {
        margin: 0;
        padding: 0;
        background: $color-2--3;
        legend {
            color: $color-white;
            text-transform: uppercase;
            font-size: 2.3rem;
            font-weight: $bold;
        }
    }

    .colPos90 {

        label {
            color: $color-white;
            font-size: 1.6rem;
            margin-bottom: 1rem;
            text-transform: initial;
        }
        .checkbox {
            label {
                padding-left: 2.5rem;
                color: #fefefe;
                font-size: 1.3rem;
                font-style: italic;
            }
        }

        .filters-close {
            display: none;
        }

        > .wrapper-main {
            max-width: inherit;
            margin: 0;
            height: 100%;
        }

        .filters__logo {
            padding-bottom: 0;
            img {
                position: relative;
                bottom: -1.5rem;
            }
        }

        .filters__header {
            max-width: calc(100% + 2rem);
            text-align: left;
            margin: 0 -1rem 4rem;
            //display: none;
            height: auto;
            background-color: $color-white;
        }

        .ddm {

            .ddm__sub-level {
                display: block;
                height: calc(100vh - 260px);
                overflow-y: auto;
            }
        }

        form {
            display: block;
            margin: 0;
            padding: 0 10px;
        }

        .filters__wrapper {
            display: block;

            .filters__fields {
                display: block;
                vertical-align: inherit;

                .filters__fields-wrapper {
                    display: block;
                    margin: 0 0 2rem;

                    > * {
                        flex: inherit;
                        margin: 0 0 2rem;
                    }

                    .label {
                        color: $color-white;
                        font-size: 1.5rem;
                        margin-bottom: 1rem;
                        text-transform: initial;
                    }

                    .checkbox {

                        legend {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }

            .filters__button-wrapper {
                position: fixed;
                bottom: 0;
                left: 1rem;
                width: 310px;
                display: block;
                padding: 1rem 0;
                white-space: normal;
                background-color: $color-2--3;

                .filters__submit {
                    float: right;
                }

                button {
                    line-height: normal;
                }
            }
        }

        .check_radio_item__sub {
            margin-top: 1rem;
            margin-left: 2rem;
        }
    }

    .list-infos_title {
        h2 {
            font-family: $typo-1;
            color: $color-2--2;
            font-size: 2.2rem;
            font-weight: $light;
        }
    }
    p.category {
        text-transform: inherit;
        color: $color-2--2;
        font-size: 1.4rem!important;
        font-weight: $bold;
    }

    .list-infos__top {
        padding: 2rem 3rem;
        margin: 0 -2rem 2.5rem;
        background-color: #fcc22e;
    }

    .list-infos-img {
        margin-top: 1.5rem;
    }

    .list-infos__item {
        padding-left: 2.7rem;
    }

    .map-content .map__popup .map__popup__container {
        padding: 0 2rem 2rem;
    }

    .side-nav-close,
    .filters-close {
        height: 0;
        overflow: hidden;
        padding-top: 30px;
        width: 30px;
        position: absolute;
        top: .5rem;
        right: .5rem;
        z-index: 110;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "\e022";
            font-family: icons-default;
            display: block;
            font-size: 18px;
            color: #fff;
            padding: .5rem;
            background: $color-2--2;
            transition: background ease .3s;
            border-radius: 50%;
        }

        &:hover, &:focus {

            &::before {
                background: $color-1--3;
            }
        }
    }

    .map__popup--active {

        .map__popup {
            display: table-cell;
            width: 300px;
            position: relative;
            z-index: 110;
            height: 100vh;
            background-color: $color-white;
        }
    }

    .map__search--active {

        .map__search {
            display: table-cell;
            width: 300px;
            background: $color-2--3;
            border: 1px solid $color-white;
        }
    }

    .map-content__container {
        display: table;
        table-layout: fixed;
        width: 100%;

        > * {
            display: table-cell;
            vertical-align: top;
        }

        .map__container {
            width: 100%;
            position: relative;
        }

        .map__tools {
            position: absolute;
            z-index: 100;
            top: 6px;
            right: 6px;
        }
    }

    .map__popup {
        display: none;
        height: 100%;
    }

    .block-info {
        padding: 2rem 2rem 2rem 4rem;
        margin-top: 3rem;
        background-color: #eaecf3;
        p {
            font-size: 1.5rem;
            color: $color-2--3;
            font-weight: $bold;
            &.block-info__title {
                margin-bottom: 1rem;
                position: relative;
                &:before {
                    position: absolute;
                    top: 0;
                    left: -2.5rem;
                    font-family: 'icons-project';
                    content: '\e00b';
                    font-size: 1.8rem;
                    color: #033162;
                }
            }
            &.block-info__description {
                font-weight: $light;
            }
        }

    }

    .map__popup__container {
        padding: 10px;
        position: relative;
        height: 100%;
        overflow-y: auto;

        .list-type-3__wrapper {
            padding-right: 40px;
        }

        .container {

            > * {
                margin-bottom: 4rem;
            }

            .button-2 {
                width: 100%;
                margin-top: 1.5rem;
                padding: 1.7rem 1rem;
                text-align: center;
                font-size: 1.3rem;
                color: $color-2--2;
                &::before {
                    content: none;
                }
                &:hover,
                &:focus {
                    color: $color-white;
                }
            }
        }
    }

    .map__search {
        display: none;
        position: relative;
        height: 100%;

        &-title {
            position: relative;
            padding: 1.5rem 0.7rem;
            min-height: 6rem;
            border-bottom: 1px solid $color-white;
            h3 {
                font-size: 1.8rem;
                color: $color-white;
            }
        }

        .side-nav-close {
            right: 2rem;
            //display: none;
        }

        .map__search__container {
            width: 100%;
        }

        .container {
            height: calc(100vh - 6rem);
            overflow-y: auto;
            outline: none;

            .jspVerticalBar {
                display: none;
            }
        }

        ul {
            margin: 5rem 0;

            &.no-margin {
                margin: 0;
            }

            > li {
                border-bottom: 1px solid $color-white;

                button {
                    display: block;
                    width: 100%;
                    padding: 10px 10px;
                    text-align: left;
                    white-space: normal;
                    transition: background ease .3s, color ease .3s;

                    &:hover, &:focus {
                        background: $color-white;
                         * {
                             color: $color-black;
                         }
                    }
                }

                .category {
                    font-size: 1.1rem;
                    font-style: italic;
                    margin-bottom: .5rem;
                    display: block;
                    color: $color-white;
                }

                .title {
                    color: $color-white;
                }

                .map__search__title {
                    font-size: 1.3rem;
                    color: $color-black;
                }
            }
        }
    }

    .map__search__arrow {
        background-color: $color-2--3;
        &:before {
            color: $color-white;
        }
        &:hover,
        &:focus {
            background-color: $color-white;
            &:before {
                color: $color-2--3;
            }
        }
    }

    .map-content {
        margin: 0;
        height: auto;
        .side-nav-close {
            top: 1rem;
            right: 1rem;
            &:before {
                font-weight: $light;
                background: none;
                font-size: 4rem;
            }
        }
    }
}

.map__search {

    .jspPane {
        margin-left: 0 !important;
        min-width: 100% !important;
        .rte {
            p {
                color: $color-white;
            }
        }
    }

    &__arrow {
        position: absolute;
        left: 0;
        width: 100%;
        display: flex;
        z-index: 100;
        height: 50px;
        justify-content: center;
        align-items: center;
        background-color: darken($color-3--1, 5%);
        font-weight: bold;
        font-size: 3rem;
        color: $color-2--2;
        transition: all 200ms ease;
        display: none;

        &:hover {
            background-color: $color-1--3;
            color: #fff;
        }

        &::before {
            font-family: icons-default;
            vertical-align: middle;
            line-height: 1;
        }

        &--visible {
            display: block;
        }

        &.scroll-up {
            top: 0;

            &::before {
                content: '\e015';
            }
        }

        &.scroll-down {
            bottom: 0;

            &::before {
                content: '\e014';
            }
        }
    }
}

.filters {

    &__logo {
        display: block;
        padding: 0 0 10px 0;

        img {
            max-width: 100%;
            width: auto;
            height: auto;
            max-height: 100px;
            display: block;
        }
    }

    &__header-title {
        font-size: 3rem;
        margin-bottom: 1.5rem;
    }

    &__header-buttons {
        width: auto;
        margin: 0 -1rem;
    }

    &__header-btn {
        width: 50%;
        margin-right: -4px;

        &--active {
            background: $color-2--3;
        }

        &::before {
            display: none;
        }
    }

    &__ddm {
        display: none;
    }

    &__hide {
        color: $color-white;
        display: block;
        width: 100%;
        text-align: right;
        margin-top: 30px;
        visibility: visible;
        transition: color 200ms ease;

        &--hidden {
            visibility: hidden;
        }

        &::after {
            content: '\e01a';
            font-family: icons-default;
            vertical-align: middle;
            font-size: 1rem;
            margin-left: 10px;
            display: inline-block;
            transition: color 200ms ease, transform 200ms ease;
        }

        &:hover, &:focus {
            color: $color-1--3;

            &::after {
                transform: translateX(-3px);
            }
        }
    }
}

.map-content {

    &--localiser {
        margin-top: 0;
    }

    .side-nav-close,
    .filters-close {
        height: 0;
        overflow: hidden;
        padding-top: 30px;
        width: 30px;
        position: absolute;
        top: .5rem;
        right: .5rem;
        z-index: 110;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "\e022";
            font-family: icons-default;
            display: block;
            font-size: 18px;
            color: #fff;
            padding: .5rem;
            background: $color-2--2;
            transition: color ease .3s;
            border-radius: 50%;
        }

        &:hover, &:focus {

            &::before {
                color: $color-2--1;
            }
        }
    }
}

.situer {
    margin: 5rem 0;

    .title-3 {

        &::before {
            content: "\e011";
            color: #b4071d;
        }

        &::after {
            background: #013f61;
        }
    }
}

.map-content {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 500px;
    margin: 1.5rem 0 0;

    &--localiser {
        margin-top: 0;
    }

    > * {
        display: table-cell;
        vertical-align: top;
    }

    .mapWrap {
        width: 100%;
        height: 500px;
        position: relative;

        .angular-openlayers-map {
            height: 500px !important;
        }

        .map__tools {
            position: absolute;
            top: .5rem;
            right: .5rem;
        }
    }

    .map__popup {
        width: 300px;
        height: 100%;
        display: none;
        position: relative;
        background: darken($color-3--1, 5%);

        * {
            color: $color-black;
        }

        .contact {
            [class*='button'] {
                color: $color-white;
                line-height: 1;
            }
        }

        p {
            font-size: 1.5rem;
        }

        &.map__popup--active {
            display: table-cell;
        }

        .map__popup__container, .popup__container {
            padding: 10px;
            height: auto;
            max-height: 100%;
            overflow-y: auto;
        }

        .list-type-3__picture {
            float: none;
            margin-bottom: 1rem;
        }

        .list-type-3__wrapper {
            padding-right: 4rem;
        }

        .list-infos__wrapper {
            display: block;

            .list-infos__listitems {
                border: none;
                margin: 0;
                padding: 0;
            }
        }

        .list-infos_title {
            h2 {
                color: $color-2--2;
                padding-right: 30px;
            }
        }

        .list-infos__item {

            &::before {
                color: $color-2--2;
            }
        }
    }
}

.map__popup {
    .list-infos .list-infos-wrapper .list-infos-img {
        margin-bottom: 1.5rem;

        a {
            float: left;
            display: block;
            margin-right: 1rem;
        }

        &::after {
            content: '';
            display: table;
            clear: both;
        }

        .list-infos_title {
            overflow: hidden;
        }
    }
}

.contact-info--agenda {

    .list-type-1__picture {
        float: none;
        display: block;
        margin: 0 0 2rem;
        text-align: center;

        img {
            margin: 0;
        }
    }

    .date-1 {
        margin-bottom: 2rem;
    }

    .hour-place {
        margin-bottom: 2rem;
    }

}

@media (max-width: 768px) {

    .carto {

        .ol-zoom {
            top: 4rem;
        }

        .colPos90 {
            position: absolute;
            display: block;
            z-index: 105;
            top: 0;
            left: 0;
            bottom: 0;
            transform: translateX(-310px);
            transition: transform ease .3s;

            .filters-close {
                display: block;
                right: -3rem;
                transition: right ease .3s;

                &::before {
                    border-radius: 0;
                    content: "\e02f";
                }
            }

            .ddm {

                .ddm__sub-level {
                    max-height: inherit;
                }
            }

            .filters__header {

                .filters__logo {
                    margin-right: 50px;
                }
            }
        }

        .filters--active {
            transform: translateX(0);
            width: 310px;

            .filters-close {
                right: .5rem;
            }
        }

        .map__search--active {

            .map__search {
                display: none;
            }
        }

        .map__search {
            display: none;
        }
    }
}

/* /map styles */
